export const oidcSettings = getSettings()

function getSettings () {
    switch (process.env.NODE_ENV) {
        case 'production': {
            return {
                authority: 'https://connect.icod.de/auth/realms/xdslvergleich.de',
                clientId: 'web-client',
                redirectUri: 'https://editor.xdslvergleich.de/oidc-callback',
                popupRedirectUri: "https://editor.xdslvergleich.de/oidc-popup-callback",
                responseType: 'code',
                scope: 'openid email profile roles',
                automaticSilentRenew: true,
                automaticSilentSignin: true,
                silentRedirectUri: 'https://editor.xdslvergleich.de/silent-renew-oidc.html'
            }
        }
        default: {
            return {
                authority: 'https://connect.icod.de/auth/realms/xdslvergleich.de',
                clientId: 'quasar-dev',
                redirectUri: 'http://qxdsladmin.local/oidc-callback',
                popupRedirectUri: "http://qxdsladmin.local/oidc-popup-callback",
                responseType: 'code',
                scope: 'openid email profile roles',
                automaticSilentRenew: true,
                automaticSilentSignin: true,
                silentRedirectUri: 'http://qxdsladmin.local/silent-renew-oidc.html'
            }
        }
    }
}
