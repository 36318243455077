<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  async created() {
    await this.$store.$db().model("anbieter").api().get("/api/v1/anbieter/");
    await this.$store.$db().model("tarif").api().get("/api/v1/tarif/");
  },
};
</script>
