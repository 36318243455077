export default function () {
  return {
    model: {
      base_price: 0.0,
      reduced_price: 0.0,
      months_reduced_price: 6,
      additional_costs: 0.0,
      ep2: 0.0,
      ep5: 0.0
    }
  }
}
