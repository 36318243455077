import { Model } from '@vuex-orm/core'
import Anbieter from './Anbieter'

export default class Tarif extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'tarif'

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            id: this.string(''),
            updated: this.attr(null),
            anbieter_id: this.string(''),
            name: this.string(''),
            grundpreis: this.number(0),
            bandbreite_down: this.number(0),
            bandbreite_up: this.number(0),
            vertragslaufzeit: this.number(0),
            installations_preis: this.number(0),
            is_flat: this.boolean(false),
            is_dsl: this.boolean(false),
            is_cable: this.boolean(false),
            is_fiber: this.boolean(false),
            has_phone_flat: this.boolean(false),
            has_tv: this.boolean(false),
            has_handy_flat: this.boolean(false),
            region: this.string(''),
            text: this.string(''),
            effektiv_preis2: this.number(0),
            effektiv_preis5: this.number(0),
            link: this.string(''),
            anbieter: this.belongsTo(Anbieter, 'anbieter_id')
        }
    }
}
