import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import Tarif from './models/Tarif'
import Anbieter from './models/Anbieter'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import { oidcSettings } from '@/config/oidc'
import calculator from './calculator'

Vue.use(Vuex)

VuexORM.use(VuexORMAxios, {
  axios,
})

// Create a new instance of Database.
const database = new VuexORM.Database()

// Register Models to Database.
database.register(Anbieter)
database.register(Tarif)

export default function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    plugins: [VuexORM.install(database)],
    modules: {
      oidcStore: vuexOidcCreateStoreModule(oidcSettings, { namespaced: true }),
      calculator,
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.NODE_ENV !== 'production'
  })

  return Store
}
