export const SET_MODEL = (state, model) => {
  state.model = model
}
export const CLEAR_MODEL = (state) => {
  state.model = {
    base_price: 0.0,
    reduced_price: 0.0,
    months_reduced_price: 6,
    additional_costs: 0.0,
    ep2: 0.0,
    ep5: 0.0
  }
}
export const SET_BASE_PRICE = (state, val) => {
  state.model.base_price = val
}

export const SET_REDUCED_PRICE = (state, val) => {
  state.model.reduced_price = val
}

export const SET_MONTHS_REDUCED_PRICE = (state, val) => {
  state.model.months_reduced_price = val
}

export const SET_ADDITIONAL_COSTS = (state, val) => {
  state.model.additional_costs = val
}

export const SET_EP2 = (state, val) => {
  state.model.ep2 = val
}
export const SET_EP5 = (state, val) => {
  state.model.ep5 = val
}
