/*
export function someAction (context) {
}
*/

export const setModel = (context, model) => {
  context.commit('SET_MODEL', model)
}

export const clearModel = (context) => {
  context.commit('CLEAR_MODEL')
}

export const setBasePrice = (context, val) => {
  context.commit('SET_BASE_PRICE', val)
}

export const setReducedPrice = (context, val) => {
  context.commit('SET_REDUCED_PRICE', val)
}

export const setMonthsReducedPrice = (context, val) => {
  context.commit('SET_MONTHS_REDUCED_PRICE', val)
}

export const setAdditionalCosts = (context, val) => {
  context.commit('SET_ADDITIONAL_COSTS', val)
}

export const setEffektivPreis2 = (context, val) => {
  context.commit('SET_EP2', val)
}

export const setEffektivPreis5 = (context, val) => {
  context.commit('SET_EP5', val)
}
