import { Model } from '@vuex-orm/core'
import Tarif from './Tarif'

export default class Anbieter extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'anbieter'

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            id: this.string(''),
            name: this.string(''),
            address: this.string(''),
            logo: this.string(''),
            tarife: this.hasMany(Tarif, 'anbieter_id')
        }
    }
}
