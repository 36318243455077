/*
export function someGetter (state) {
}
*/
export const getModel = (state) => {
    return state.model
}

export const getBasePrice = (state) => {
    return state.model.base_price
}

export const getReducedPrice = (state) => {
    return state.model.reduced_price
}

export const getMonthsReducedPrice = (state) => {
    return state.model.months_reduced_price
}

export const getAdditionalCosts = (state) => {
    return state.model.additional_costs
}

export const getEffektivPreis2 = (state) => {
    return state.model.ep2
}

export const getEffektivPreis5 = (state) => {
    return state.model.ep5
}
