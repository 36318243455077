import Vue from 'vue'
import VueRouter from 'vue-router'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      { path: '', redirect: '/tarife/' },
      { path: '/tarife/', component: () => import('@/views/Tarife.vue')},
      { path: '/edit-tarif/:id', component: () => import('@/views/EditTarif.vue') },
      { path: '/new-tarif/', component: () => import('@/views/NewTarif.vue') },
      { path: '/anbieter/', component: () => import('@/views/Anbieter.vue')},
      { path: '/edit-anbieter/:id', component: () => import('@/views/EditAnbieter.vue') },
      { path: '/new-anbieter/', component: () => import('@/views/NewAnbieter.vue') }
    ]
  },
  // add new routes here
  {
    path: '/oidc-callback', // Needs to match redirectUri (redirect_uri if you use snake case) in you oidcSettings
    name: 'oidcCallback',
    component: () => import('@/views/OidcCallback.vue'),
    meta: {
      isPublic: true
    }
  },
  {
    path: '/oidc-popup-callback', // Needs to match popupRedirectUri in you oidcSettings
    name: 'oidcPopupCallback',
    component: () => import('@/views/OidcPopupCallback.vue'),
    meta: {
      isPublic: true
    }
  },
  {
    path: '/oidc-callback-error', // Needs to match redirect_uri in you oidcSettings
    name: 'oidcCallbackError',
    component: () => import('@/views/OidcCallbackError.vue'),
    meta: {
      isPublic: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(vuexOidcCreateRouterMiddleware(store(), 'oidcStore'))

export default router
